import React, { useEffect, useState } from 'react'
import { MDBBtn, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalHeader, MDBSpinner, MDBIcon } from 'mdb-react-ui-kit';
import ModelSlider from './modelSlider';
import appConfig from '../../configs/App.config';
import { useNavigate } from 'react-router-dom';


const CBOLoginModel = ({ isModalOpen, setIsModalOpen, switchModal, toggleOpen, setPrevModal }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    //show/hide password
    const [passwordType, setPasswordType] = useState('password');
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
        
    const togglePasswordVisibility = () => {
            setPasswordType(passwordType === 'password' ? 'text' : 'password');
            setIsPasswordVisible(!isPasswordVisible);
    }

    useEffect(() => {
        if(isModalOpen){
            document.querySelectorAll('.modal-bg *').forEach((element) => {
                element.setAttribute('tabIndex', '-1');
            });

            // set attribute to trhe form elements
            setTimeout(() => {
            document.getElementById('CboUser').setAttribute('tabIndex', '1');
            document.getElementById('CboPassword').setAttribute('tabIndex', '2');
            document.getElementById('cbo-login-btn').setAttribute('tabIndex', '3');
            document.getElementById('forgotPassword').setAttribute('tabIndex', '4');
            
                document.getElementById('CboUser').focus();
                var e = new KeyboardEvent('keypress', {'key': 'Tab'});
                document.getElementById('CboUser').dispatchEvent(e);
            }, 1000);
        }
    }, [isModalOpen]);

    const handleCboLogin = (e) => {
        e.preventDefault();
        setIsLoading(true);
        setErrors({});
        // Validations
        let errors = {};
        if (email === '') {
            errors.email = "Email is required";
        } 
        // else if (email !== '') {
        //     if (!/\S+@\S+\.\S+/.test(email)) {
        //         errors.email = "Email address is invalid";
        //     }
        // }
        if (password === '') {
            errors.password = "Password is required";
        }
        
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            setIsLoading(false);
            return;
        }
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "USER_NAME": email,
            "PASSWORD": btoa(password), //password,
            "USER_TYPE": "CBO"
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${appConfig.apiBaseUrl}/auth/login`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.statusCode >= 400) {
                    setErrors({"message": result.errMessage});
                    setIsLoading(false)
                } else {
                    // redirect to /auth/verify#token=token
                    if (result.token) {
                        // window.location.replace(`/auth/verify#token=${result.token}`);
                        navigate(`/auth/verify#token=${result.token}`);
                    } else {
                        setErrors({ "message": "Failed to login, please try again." })
                    }
                    setIsLoading(false)
                }
                setIsLoading(false);
            })
            .catch(error => {
                console.log('error', error)
                setIsLoading(false);});
    }

    return (
        <MDBModal
            backdrop={false}
            show={isModalOpen}
            setShow={setIsModalOpen}
            tabIndex='-1'
            className='shadow-2'>
            <MDBModalDialog centered size='lg'>
                <MDBModalContent className='modal-bg'>
                    <MDBModalHeader>
                        <img className="login-logo "
                            src="/images/logo-ejdh.png" alt="" />
                        <MDBBtn className='btn-close' color='none' onClick={() => toggleOpen('cbo')}>
                            <span className="material-symbols-outlined">
                                close
                            </span>
                        </MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    <ModelSlider />
                                </div>
                                <div className="col-md-6">
                                    <div className="form-box">
                                        <form onSubmit={handleCboLogin}>
                                        <div className="inner-form-box">
                                            <h3 className="form-title">CBO Login</h3>
                                            <i className="fa-solid fa-users form-icon"></i>
                                            <p style={{ color: "#7b7b7b" }}><strong>Login to access your account </strong></p>
                                            {Object.keys(errors).length > 0 && <div className="form-floating mb-3 text-danger">
                                                {Object.values(errors).map((val, index) => {
                                                    return <p style={{
                                                        fontSize: "15px",
                                                        marginBottom: "5px",
                                                        lineHeight: "1rem"
                                                    }} key={index}>{val}</p>
                                                })}
                                            </div>}
                                            <div className="form-floating mb-3">
                                                <i className="fa-solid fa-user flot-icon"></i>
                                                <input type="email" className="form-control rounded-3" id="CboUser"
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    placeholder="Username" />
                                                <label htmlFor="CboUser">Username</label>
                                            </div>
                                            <div className="form-floating mb-3 position-relative">
                                                <i className="fa-solid fa-key flot-icon"></i>
                                                <input type={passwordType} className="form-control rounded-3" id="CboPassword"
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    placeholder="Password" />
                                                <label htmlFor="CboPassword">Password</label>
                                                <MDBIcon far icon={isPasswordVisible ? 'eye-slash' : 'eye'} id='togglePassword' onClick={togglePasswordVisibility} style={{top: "15px", right: "15px"}} />
                                            </div>
                                            {/* <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="RememberMeChecked"
                                        checked />
                                        <label className="form-check-label" htmlFor="RememberMeChecked">
                                            Remember me
                                        </label>
                                </div> */}
                                            <button className="mb-2 btn btn-sm rounded-3 btn-primary form-btn" disabled={isLoading}
                                                onClick={handleCboLogin}
                                                id='cbo-login-btn'
                                                type="submit">
                                                {isLoading && <MDBSpinner size='sm' className='mr-1' role='status' tag='span' />}
                                                Login
                                            </button>
                                            <div className="password-label" type="button">
                                                <a id="forgotPassword" href="#" onClick={() => {
                                                    setPrevModal('cbo');
                                                    switchModal('fpwd');
                                                }}>Forgot password?</a>
                                            </div>
                                        </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </MDBModalBody>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>

    )
}

export default CBOLoginModel
