import React, { useEffect, useState } from 'react';
import GaugeComponent from './GaugeComponent';
import GaugeLoader from './GaugeLoader';
import './RenderGauge.css';
import CommonServoces from '../../../services/commonServices';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { selectZipCode, selectChemicalId } from '../../../redux/appSlice';
import { useParams } from 'react-router-dom';
import { selectTokenData } from '../../../redux/authSlice';
import HealthImpactTxt from '../../app/dashboardTxt/HealthImpactTxt';

const Loader = () => (
  <div className="loader">
      <GaugeLoader />
  </div>
);

const RenderGuage = () => {
  const zipCode$ = useSelector(selectZipCode);
  const { zipcode } = useParams();
  const chemicalId$ = useSelector(selectChemicalId);
  const { chemicalId } = useParams();
  const [gaugeData, setGaugeData] = useState(null);
  const userData$ = useSelector(selectTokenData);
  const [userData, setUserData] = useState(userData$);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (chemicalId$ === null) {
      return;
    }
    CommonServoces.getChemicalsResponse(chemicalId$)
      .then((response) => {  
        Object.keys(response).map(r => console.log(r, response[r]));
        if (response.error) {
          toast.error(`Data Error: ${response.error.code}: ${response.error.message}`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          return;
        }
        if (response && response.data[0]) {
          setGaugeData(response.data[0]);
        } else {
          setGaugeData({});
        }
        setIsLoading(false);
      }).catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [chemicalId$]);

  useEffect(() => {
    setGaugeData(null);
  }, [zipCode$]);

  const mapIntensity = (value) => {
    if (value === null) {
      return 0;
    }

    switch (value.toLowerCase()) {
      case 'very high':
      case 'very high to high':
        return 8.3;
      case 'high':
      case 'high to moderate':
        return 24.9;
      case 'moderate':
      case 'moderate to low':
        return 41.5;
      case 'low':
      case 'very low':
        return 58.1;
      case 'very high to moderate':
      case 'very high to low':
      case 'high to low':
      case 'potential concern':
      case 'unspecified':
        return 74.7;
      default:
        return 0;
    }
  };

  const mapLabel = (label) => {
    if (label.includes('_')) {
      return label
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    } else {
      return label.charAt(0).toUpperCase() + label.slice(1);
    }
  };

  const mapDescription = (label) => {
    switch (label) {
      case 'carcinogenicity':
        return 'Likelihood of a substance to cause cancer.';
      case 'developmental_toxicity':
        return 'A substance that can cause harm to a developing child.';
      case 'neurotoxicity_single_exposure':
        return 'One-time contact with substances that disrupt the nervous system (the body’s messaging system).';
      case 'eye_irritation':
        return 'Feelings of dryness, itchiness, pain, or grittiness, or redness in the eyes.';
      case 'Skin_Irritation':
        return 'A noticeable change in the skin (e.g., red, inflamed, and bumpy skin).';
      case 'reproductive_toxicity':
        return 'Risk to reproductive health, including the ability to have children.';
      case 'Respiratory_Sensitization':
        return 'An inhalant causing permanent allergic airway reactions like asthma.';
    }
  };

  const legends = [
    {
      name: "Very High Hazard",
      color: "#ff5656"
    },
    {
      name: "High Hazard",
      color: "#ff8888"
    },
    {
      name: "Moderate Hazard",
      color: "#fee114"
    },
    {
      //name: "Wide range of possible hazard levels",
      name: "Low Hazard",
      color: "#d1d80f"
    },
    {
      //name: "Potential Concern",
      name: "Very Low Hazard",
      color: "#84bd32"
    },
    {
      //name: "Low Hazard",
      name: "Potential Concern",
      color: "#30ad43"
    }
  ];

  const legendItems = legends.map(data => ({
    name: data.name,
    color: data.color
  }));

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        gaugeData && ((Object.keys(gaugeData) && Object.keys(gaugeData).length > 0) ? (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className='card mt-2 mb-2 p-4'>
              <HealthImpactTxt />
            </div>
            {/* LEGENDS */}
            <div className="legend-container mb-4">
              <div className="legends-title">
                LEGEND
              </div>

              {/* Added the items in the LEGENDS as per the UX.*/}
              {/* Separated into two columns with 3 items in each column */}
              <div className="legend-column" style={{ left: '50px' }}>
                {legendItems.slice(0, 6).map((item, index) => (
                  <div key={index} className="legend-item">
                    <div className="legend-color" style={{ backgroundColor: item.color }}></div>
                    <span style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>{item.name}</span>
                  </div>
                ))}
              </div>

              {/* <div className="legend-column" style={{ left: '250px' }}>
                {legendItems.slice(3, 6).map((item, index) => (
                  <div key={index} className="legend-item">
                    <div className="legend-color" style={{ backgroundColor: item.color }}></div>
                    <span style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>{item.name}</span>
                  </div>
                ))}
              </div> */}
            </div>

            {/* Rendering gauge charts */}
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '10px', marginBottom: '10px' }}>
              {Object.keys(gaugeData).slice(0, 6).map((k, index) => (
                <GaugeComponent key={index} intensity={mapIntensity(gaugeData[k])} label={mapLabel(k)} description={mapDescription(k)} />
              ))}
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
              <div style={{ width: '40%' }}>
                <GaugeComponent
                  intensity={mapIntensity(Object.values(gaugeData)[6])}
                  label={mapLabel(Object.keys(gaugeData)[6])}
                  description={mapDescription(Object.keys(gaugeData)[6])}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="no-chemicals"> No Chemicals Available for {userData.USER_TYPE === "CBO" ?
            ` ${userData.FULLNAME}` :
            ` zip code ${zipCode$}`} </div>
        ))
      )}
    </>
  );
}

export default RenderGuage;