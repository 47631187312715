import React from 'react'
import './homePage.css'

const FooterComponent = () => {
    return (
        <>
            <div className="footer">

            <div className="waves">
      <div className="wave" id="wave1"></div>
      <div className="wave" id="wave2"></div>
      <div className="wave" id="wave3"></div>
      <div className="wave" id="wave4"></div>
    </div>

                <div className="container">
                    <div className="row" style={{paddingLeft: "10%"}}>
                        <div className="col-lg-4 col-sm-4 col-xs-12">
                            <div className="single_footer">
                                <h4>Useful Links</h4>
                                <ul>
                                    <li><a href="/about">About EJDAP</a></li>
                                    <li><a href="https://www.dscej.org/our-story" target="_blank">About DSCEJ</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="single_footer single_footer_address">
                                <h4>Terms & Conditions</h4>
                                <ul>
                                    <li><a href="/privacy-policy">Privacy Policy</a></li>
                                    <li><a href="/cookies-policy">Cookies Policy</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="single_footer single_footer_address">
                                <h4>Follow Us</h4>
                                <ul className="social-links">
                                    <li><a href="https://twitter.com/DSCEJ" target='_blank' className="twtr"><i className="fab fa-x-twitter"></i> X</a></li>
                                    <li><a href="https://www.linkedin.com/company/dscej/" target='_blank' className="lkdn"><i className="fab fa-linkedin" aria-hidden="true"></i> Linkedin</a></li>
                                    <li><a href="https://www.facebook.com/DSCEJNOLA" target='_blank' className="fb"><i className="fab fa-facebook-f"></i> Facebook</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-sm-12 col-xs-12">
                                <p className="copyright">Copyright © 2024 <a href="#">Deep South Center for Environmental Justice</a>.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FooterComponent
