import appConfig from "../configs/App.config";
import chartsConfig from "../configs/charts.config";

// TODO: Requires to add custom color pallet for each feature
const ChartMaker = {
    slicedData: undefined,

    makeAreaChart: (data) => {
        return data;
    },
    makePieChart: (data, type, triDataFlag = null) => {
        const options = chartsConfig.pie;
        const series = {};
        if (type === 'GHG') {
            // gey sum of each year for each feature for pie chart
            let legends = data.features.sort();
            let graphData = data.data.map((feature) => {
                return {
                    name: feature.feature.length > 25 ? feature.feature.substring(0, 25) + "..." : feature.feature,
                    y: data.years.reduce((acc, year) => {
                        acc += feature[`_${year}`];
                        return acc;
                    }),
                    legendIndex: legends.indexOf(feature) + 1
                }
            });
            options.series[0].data = graphData;
            ChartMaker.slicedData = options.series[0].data;
        } else {
            if (triDataFlag === 'chemical') {
                data.data.map((feature) => {
                    feature?.children?.map((chemical) => {
                        if (!series[chemical.feature]) {
                            series[chemical.feature] = 0;
                        }

                        data.years.map((year) => {
                            series[chemical.feature] += Number(chemical[`_${year}`]);
                        });
                    });
                });
            } else {
                data.data.map((feature) => {
                    if (!series[feature.feature]) {
                        series[feature.feature] = 0;
                    }

                    data.years.map((year) => {
                        series[feature.feature] += Number(feature[`_${year}`]);
                    });
                });
            }
            
            let sortedArray = Object.entries(series).sort((a, b) => b[1] - a[1]);
            sortedArray = sortedArray.slice(0, chartsConfig.sliseData);
            options.series[0].data = sortedArray.reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
                }, {})
                
            ChartMaker.slicedData = options.series[0].data;

            let legends = Object.keys(data.features).sort();
            options.series[0].data = Object.keys(options.series[0].data).map((key) => {
                return {
                    name: key.length > 25 ? key.substring(0, 24) + "..." : key,
                    y: series[key],
                    legendIndex: legends.indexOf(key) + 1
                }
            });
        }

        // options.title.text = chartsConfig.pieChartTitle + " " + data.years[0] + '-' + data.years[data.years.length - 1];
        options.title.text = appConfig.lables[type.toLowerCase()].pieChart;
        return options;
    },
    makeGHGChart: (data, tab) => {
        const options = chartsConfig.area;
        let yData = {};
        let series = data.data.map((feature) => {

            return {
                name: feature.feature,
                data: data.years.map((year) => {
                    if (!yData[`_${year}`]) {
                        yData[`_${year}`] = 0;
                    }
                    yData[`_${year}`] += Number(feature[`_${year}`]);
                    return feature[`_${year}`];
                })
            }
        });

        // options.series = series;
        options.series = {
            name: tab.toUpperCase(),
            data: Object.values(yData)
        }
        options.plotOptions.area.pointStart = data.years[0];
        options.xAxis.accessibility.rangeDescription = `Range: ${data.years[0]} to ${data.years[data.years.length - 1]}.`;
        options.yAxis.title.text = options.yAxis.title.text.replace('{activeTab}', tab.toUpperCase());
        // options.title.text = (chartsConfig.trendsChartTitle.replace('{activeTab}', tab.toUpperCase())) + " " + data.years[0] + '-' + data.years[data.years.length - 1];
        options.title.text = appConfig.lables[tab.toLowerCase()].lineGraph;
        options.title.text = options.title.text.replace('{zipcode}', data.zipCode);
        return options;
    },
    makeTRIChart: (data, tab) => {
        const options = chartsConfig.tri;
        let series = {};
        data.data.map((feature) => {
            feature.children.map((chemical) => {
                if (!series[chemical.feature]) {
                    series[chemical.feature] = {};
                }

                data.years.map((year) => {
                    if (!series[chemical.feature][year]) {
                        series[chemical.feature][year] = 0;
                    }
                    series[chemical.feature][year] += Number(chemical[`_${year}`]);
                });
            });
        });


        if (ChartMaker.slicedData) {
            let slicedSeries = {};
            // get only if exists in slicedData
            Object.keys(series).map((key) => {
                // if (ChartMaker.slicedData[key]) {
                slicedSeries[key] = series[key];
                // }
            });
            series = slicedSeries;
        }

        let legends = new Set(Object.keys(series).sort());
        legends = [...legends];
        options.series = Object.keys(series).map((key) => {
            return {
                name: key.length > 25 ? key.substring(0, 25) + "..." : key,
                data: Object.values(series[key]),
                legendIndex: legends.indexOf(key) + 1
            }
        });

        options.xAxis.categories = data.years;
        options.title.text = (chartsConfig.trendsChartTitle.replace('{activeTab}', tab.toUpperCase()));
        options.title.text = options.title.text.replace('{zipcode}', data.zipCode);

        return options;
    },
    makeSVIChart: (data, tab) => {
        return data;
    },
    makeSVMChart: (data, tab) => {
        return data;
    },
    makeHVIChart: (data, tab) => {
        return data;
    },
    makeHVMChart: (data, tab) => {
        return data;
    },
    makeEJIChart: (data, tab) => {
        return data;
    },
    makeGaugeChart: (data) => {
        const options = chartsConfig.gauge;
    },

    make: (type, data) => {
        type = type.toLowerCase();
        switch (type) {
            case 'pie':
                return ChartMaker.makePieChart(data, type);
            case 'tri':
                return ChartMaker.makeTRIChart(data, type);
            // case 'ghg':
            //     return ChartMaker.makeGHGChart(data, type);
            // case 'SVI':
            //     return ChartMaker.makeSVIChart(data, type);
            // case 'SVM':
            //     return ChartMaker.makeSVMChart(data, type);
            // case 'HVI':
            //     return ChartMaker.makeHVIChart(data, type);
            // case 'HVM':
            //     return ChartMaker.makeHVMChart(data, type);
            // case 'EJI':
            //     return ChartMaker.makeEJIChart(data, type);

            default:
                // return ChartMaker.makeAreaChart(data, type);
                return ChartMaker.makeGHGChart(data, type);
        }
    }
}

export default ChartMaker;