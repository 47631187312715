import GhgMapTxt from '../modules/app/dashboardTxt/GhgMapTxt'
import TriMapTxt from '../modules/app/dashboardTxt/TriMapTxt'

const appConfig = {
    title: 'Environmental Justice Data Action Portal',
    // publicSiteUrl: 'https://ejdhsandbox01.blob.core.windows.net/b2cauth/index.html',
    publicSiteUrl: '/',
    resFPDUrl: 'https://icejdatahub.b2clogin.com/icejdatahub.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_Resident_change_pwd&client_id=ff7abb9c-5ec3-458f-b2b4-5de71993d1a4&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fappdev.orchv.com%2F&scope=openid&response_type=id_token&prompt=login',
    cboFPDUrl: 'https://icejdatahub.b2clogin.com/icejdatahub.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_CBO_change_pwd&client_id=ff7abb9c-5ec3-458f-b2b4-5de71993d1a4&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fappdev.orchv.com%2F&scope=openid&response_type=id_token&prompt=login',
    cboSignOut: 'https://icejdatahub.b2clogin.com/icejdatahub.onmicrosoft.com/B2C_1_CBO_Signin/oauth2/v2.0/logout?post_logout_redirect_uri=https%3A%2F%2Fejdhsandbox01.blob.core.windows.net%2Fb2cauth%2Findex.html',
    resSignOut: 'https://icejdatahub.b2clogin.com/icejdatahub.onmicrosoft.com/B2C_1_Signup_Signin_01/oauth2/v2.0/logout?post_logout_redirect_uri=https%3A%2F%2Fejdhsandbox01.blob.core.windows.net%2Fb2cauth%2Findex.html',
    fpdPwd:'https://icejdatahub.b2clogin.com/icejdatahub.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_Resident_change_pwd&client_id=ff7abb9c-5ec3-458f-b2b4-5de71993d1a4&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fappdev.orchv.com%2Fauth%2Fverify&scope=openid&response_type=id_token&prompt=login',
    apiBaseUrl: 'https://ejdh.azurewebsites.net',
    // apiBaseUrl: 'http://localhost:8080',
    maxUserNameLength: 15,
    idelTimeOut: 15, // in minutes

    //  Tabs
    dashboardTabs: [
        { 
            title: 'GHG',
            key: 'GHG',
            icon: 'co2',
            isActive: true,
        },
        { 
            title: 'TRI',
            key: 'TRI',
            icon: 'warning',
            isActive: false,
        },
        // SVI, SVM, HVI, HVM, EJI
        {
            title: 'SVI',
            key: 'SVI',
            icon: 'supervisor_account',
            isActive: false,
        },
        {
            title: 'SVM',
            key: 'SVM',
            icon: 'donut_small',
            isActive: false,
        },
        // {
        //     title: 'HVI',
        //     key: 'HVI',
        //     icon: 'donut_small',
        //     isActive: false,
        // },
        {
            title: 'HVM',
            key: 'HVM',
            icon: 'health_and_safety',
            isActive: false,
        },
        {
            title: 'EBM',
            key: 'EBM',
            icon: 'forest',
            isActive: false,
        },
        { 
            title: 'CEJST',
            key: 'CEJST',
            icon: 'thermostat',
            isActive: false,
        },
        { 
            title: 'Health Impacts',
            key: 'CHEM',
            icon: 'hub',
            isActive: false,
        },
    ],

    // Labels
    lables:{
        ghg: {
            mapTitle: 'Greenhouse Gas Emissions at {zipcode}',
            // pieChart: 'Facilities percentage share of total Green House Gas Emissions',
            // lineGraph: 'Total  Green House Gas Emission for last 10 years',
            lineGraph: '',
            table:"Greenhouse Gas Emission as per each facility {currentyear}",
            units: "Units in Metric Tons"
        },
        tri: {
            mapTitle: 'Toxic Release Inventory at {zipcode}',
            // pieChart: 'Facilities percentage share of total Emission -  Toxic Release Inventory',
            lineGraph: 'Toxic Release Inventory - Total Emissions for the last 10 years ',
            table:"Toxic Release Inventory Emissions as per each facility {currentyear}",
            units: "Units in Pounds"
        },
        svi: {
            mapTitle: 'ATSDR Social Vulnerability Index 2021 at {zipcode}',
            pieChart: null,
            lineGraph: null,
            table: null
        },
        svm: {
            mapTitle: 'ATSDR EJI: Social Vulnerability Module 2021 at {zipcode}',
            pieChart: null,
            lineGraph: null,
            table: null
        },
        eji: {
            mapTitle: 'ATSDR EJI: Social Vulnerability Index {year} at {zipcode}',
            pieChart: null,
            lineGraph: null,
            table: null
        },
        hvm: {
            mapTitle: 'ATSDR EJI: Health Vulnerability Module 2021 at {zipcode}',
            pieChart: null,
            lineGraph: null,
            table: null
        },
        ebm: {
            mapTitle: 'ATSDR EJI: Environmental Burden Module 2021 at {zipcode}',
            pieChart: null,
            lineGraph: null,
            table: null
        },
        cejst:{
            mapTitle: 'Climate and Economic Justice Index 2021 at {zipcode}',
            pieChart: null,
            lineGraph: null,
            table: null
        },
        chem:{
            mapTitle: 'Chemical Dashboard for {zipcode}',
            pieChart: null,
            lineGraph: null,
            table: null
        }
    },

    // API
    // trendsGridFirstCol: '{tab} Emissions by Gas,MMT CO2 eq.',
    trendsGridFirstCol: 'Facility Name',
    defaultZipCode: '80101', // 70734
    defaultComunityId: '1104',
    locationApiUrl: 'https://ziptasticapi.com/{zip_code}',

    staticText: {
        ghg: {
            mapTxt: <GhgMapTxt />,
            pieTxt: "",
            lineTxt: "",
            tableTxt: ""
        },
        tri: {
            mapTxt: <TriMapTxt />,
            pieTxt: "",
            lineTxt: "",
            tableTxt: ""
        },
    },





    mapUrls: {
        res: 'https://ejdhsandbox01.blob.core.windows.net/arcgiswebapp/v6/index.html?zipcode={zip_code}&mapmode={tab}',
        cbo: 'https://ejdhsandbox01.blob.core.windows.net/arcgiswebapp/v6/index.html?comunityid={zip_code}&mapmode={tab}',
        
        
        ghg: {
            res: 'https://ejdhsandbox01.blob.core.windows.net/arcgiswebapp/v6/index.html?zipcode={zip_code}&mapmode=ghg',
            cbo: 'https://ejdhsandbox01.blob.core.windows.net/arcgiswebapp/v6/index.html?comunityid={zip_code}&mapmode=ghg',
        },
        tri: {
            res: 'https://ejdhsandbox01.blob.core.windows.net/arcgiswebapp/v6/index.html?zipcode={zip_code}&mapmode=tri',
            cbo: 'https://ejdhsandbox01.blob.core.windows.net/arcgiswebapp/v6/index.html?comunityid={zip_code}&mapmode=tri'
        },
        svi: {
            res: 'https://ejdhsandbox01.blob.core.windows.net/arcgiswebapp/v6/index.html?zipcode={zip_code}&mapmode=svi',
            cbo: 'https://ejdhsandbox01.blob.core.windows.net/arcgiswebapp/v6/index.html?comunityid={zip_code}&mapmode=svi',
        },
        svm: {
            res: 'https://ejdhsandbox01.blob.core.windows.net/arcgiswebapp/v6/index.html?zipcode={zip_code}&mapmode=svm',
            cbo: 'https://ejdhsandbox01.blob.core.windows.net/arcgiswebapp/v6/index.html?comunityid={zip_code}&mapmode=svm',
        },
        hvm: {
            res: 'https://ejdhsandbox01.blob.core.windows.net/arcgiswebapp/v6/index.html?zipcode={zip_code}&mapmode=hvm',
            cbo: 'https://ejdhsandbox01.blob.core.windows.net/arcgiswebapp/v6/index.html?comunityid={zip_code}&mapmode=hvm',
        },
        ebm: {
            res: 'https://ejdhsandbox01.blob.core.windows.net/arcgiswebapp/v6/index.html?zipcode={zip_code}&mapmode=ebm',
            cbo: 'https://ejdhsandbox01.blob.core.windows.net/arcgiswebapp/v6/index.html?comunityid={zip_code}&mapmode=ebm',
        },
        cejst: {
            res: 'https://ejdhsandbox01.blob.core.windows.net/arcgiswebapp/v6/index.html?zipcode={zip_code}&mapmode=cejst',
            cbo: 'https://ejdhsandbox01.blob.core.windows.net/arcgiswebapp/v6/index.html?comunityid={zip_code}&mapmode=cejst',
        }
    },
    trendsApiUrls: {
        res: 'https://geodev.orchv.com/geoserver/Geo/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=Geo:zip_ghg&cql_filter=ZIP_CODE={zip_code}&outputformat=json',
        cbo: 'https://geodev.orchv.com/geoserver/Geo/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=Geo:comunity_ghg&cql_filter=Comunity_id={zip_code}&outputformat=json',
        

        ghg: {
            res: 'https://geodev.orchv.com/geoserver/Geo/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=Geo%3Azip5_ghg&cql_filter=ZIP_CODE={zip_code}&outputformat=json',
            cbo: 'https://geodev.orchv.com/geoserver/Geo/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=Geo:comunity_ghg&cql_filter=Comunity_id={zip_code}&outputformat=json',
        },
        tri: {
            res: 'https://geodev.orchv.com/geoserver/Geo/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=Geo%3Azip5_TriView&cql_filter=ZIP_CODE={zip_code}&outputformat=json',
            cbo: 'https://geodev.orchv.com/geoserver/Geo/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=Geo%3Acomunity_TriView&cql_filter=Comunity_id={zip_code}&sortBy=REPORTING_YEAR%20A&outputformat=json',
        },
        svi: {
            res: 'https://geodev.orchv.com/geoserver/Geo/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=Geo%3Azip5_facility&cql_filter=ZIP_CODE={zip_code}&outputformat=json',
            cbo: 'https://geodev.orchv.com/geoserver/Geo/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=Geo%3Acomunity_facility&cql_filter=Comunity_id={zip_code}&outputformat=json',
            },
        svm: {
            res: 'https://geodev.orchv.com/geoserver/Geo/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=Geo%3Azip5_facility&cql_filter=ZIP_CODE={zip_code}&outputformat=json',
            cbo: 'https://geodev.orchv.com/geoserver/Geo/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=Geo%3Acomunity_facility&cql_filter=Comunity_id={zip_code}&outputformat=json',
        },
        hvm: {
            res: 'https://geodev.orchv.com/geoserver/Geo/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=Geo%3Azip5_facility&cql_filter=ZIP_CODE={zip_code}&outputformat=json',
            cbo: 'https://geodev.orchv.com/geoserver/Geo/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=Geo%3Acomunity_facility&cql_filter=Comunity_id={zip_code}&outputformat=json',
        },
        ebm: {
            res: 'https://geodev.orchv.com/geoserver/Geo/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=Geo%3Azip5_facility&cql_filter=ZIP_CODE={zip_code}&outputformat=json',
            cbo: 'https://geodev.orchv.com/geoserver/Geo/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=Geo%3Acomunity_facility&cql_filter=Comunity_id={zip_code}&outputformat=json',
        },
        cejst: {
            res: 'https://geodev.orchv.com/geoserver/Geo/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=Geo%3Azip5_facility&cql_filter=ZIP_CODE={zip_code}&outputformat=json',
            cbo: 'https://geodev.orchv.com/geoserver/Geo/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=Geo%3Acomunity_facility&cql_filter=Comunity_id={zip_code}&outputformat=json',
        },








        sgst: 'https://geodev.orchv.com/geoserver/Geo/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=Geo:zip_ghg&cql_filter=ZIP_CODE={zip_code}&outputformat=json'
    },
    pharosApiUrls:{
        res: 'https://geodev.orchv.com/geoserver/Geo/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=Geo%3Apharos_chemical_list_zip&cql_filter=ZIP_CODE={zip_code}&outputformat=json',
        cbo: 'https://geodev.orchv.com/geoserver/Geo/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=Geo%3Apharos_chemical_list_comunity&cql_filter=Comunity_id={zip_code}&outputformat=json',
        details: 'https://geodev.orchv.com/geoserver/Geo/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=Geo%3Apharos_chemical_detail&cql_filter=id={id}&outputformat=json'
    },
    
    

    // App Insights
    instrumentationKey:'2a330d45-f8c8-4c5b-9c55-701dffbe9592',
    connectionString:'InstrumentationKey=2a330d45-f8c8-4c5b-9c55-701dffbe9592;IngestionEndpoint=https://eastus-8.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/',
};

export default appConfig;
