import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {
    MDBBtn,
    MDBContainer,
    MDBIcon,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBNavbarToggler,
    MDBInputGroup
} from 'mdb-react-ui-kit'
import { selectMiniLeftNav, selectShowLeftnav, setMiniLeftNav, setShowLeftnav, setZipCode } from '../../../redux/appSlice';
import appConfig from '../../../configs/App.config';
import './Topbar.css';
import UserTray from '../userTray/UserTray';
import NotificationsTray from '../notificationsTray/NotificationsTray';
import { selectTokenData } from '../../../redux/authSlice';
import { useNavigate } from 'react-router-dom';
import CBOSearch from '../cboSearch/CBOSearch';
import CBOLoginModel from '../../../modules/home/cboLoginModel';

const Topbar = () => {
    const userData = useSelector(selectTokenData);
    const [searchTerm, setSearchTerm] = useState('');
    const [userType, setUserType] = useState('');
    const dispatch = useDispatch();
    const toggleShow = () => {
        dispatch(setShowLeftnav());
    };

    const isMiniLeftnav = useSelector(selectMiniLeftNav);
    const showLeftNav = useSelector(selectShowLeftnav);
    const navigate = useNavigate();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalType, setModalType] = useState(null);
    const [prevModal, setPrevModal] = useState(null);

    const toggleOpen = (mod) => {
        setModalType(mod);
        setIsModalOpen(!isModalOpen);
    };

    useEffect(() => {
        setUserType(userData? userData.USER_TYPE : '');
    }, [userData]);

    const collapseHandler = () => {
        if (!showLeftNav) {
            dispatch(setShowLeftnav());
        }
        dispatch(setMiniLeftNav());
    }


    const searchOnchange = (e) => {
        setSearchTerm(e.target.value);
    }

    const [rotate, setRotate] = useState("rotate-0 text-light menu-colaps p-0");
 
    const changeRotate = () => {
        console.log("you just clicked");
        if (rotate !== "rotate-0 text-light menu-colaps p-0") setRotate("rotate-0 text-light menu-colaps p-0");
        else setRotate("rotate-180 text-light menu-colaps p-0");
    };

    const doSearch = () => {
        if (isMiniLeftnav) {
            dispatch(setMiniLeftNav());
            return;
        }

        dispatch(setZipCode(searchTerm));
        // navigate('/app/search/'+searchTerm);
        setSearchTerm("");
    }

    const handleLogoutClick = () => {
        if (isMiniLeftnav) {
            dispatch(setMiniLeftNav());
        }
        
        dispatch(setShowLeftnav());
    };

    return (
        <>
            <MDBNavbar expand='lg' dark bgColor='primary' className='top-bar p-0'>
                <MDBContainer fluid className='p-0'>
                    <MDBNavbarNav className="d-flex flex-row align-items-center w-auto  top-bar-brand">
                        <MDBNavbarToggler
                            type='button'
                            className={`bg-light text-primary ${!showLeftNav ? 'hide' : ''}`}
                            aria-label='Toggle navigation'
                            onClick={toggleShow}
                        >
                            <MDBIcon icon='bars' fas />
                        </MDBNavbarToggler>
                        <div className={`custome-brand ${isMiniLeftnav ? 'custome-brand-mini' : ''}`} >
                            <MDBNavbarBrand href='/app/dashboard' children="div"
                                style={{
                                    justifyContent: "center"
                                }}>
                                <img
                                    src='/images/logo-ejdh.png'
                                    alt=''
                                    loading='lazy'
                                />
                            </MDBNavbarBrand>

                        </div>
                        <MDBBtn color='link' className={rotate}
                        onClick={()=>{
                            collapseHandler();
                            changeRotate();
                        }} >
                          <MDBIcon fas icon="angle-left" />
                        </MDBBtn>

                        <h5 className='pt-2 text-light nav-title'>
                            {appConfig.title}
                        </h5>

                    </MDBNavbarNav>
                    <MDBNavbarNav className="d-flex flex-row justify-content-end w-auto">
                        { userType !=='CBO' && <><div className='search-box hide-in-mobile'>
                            <MDBInputGroup className=''>
                                <input
                                    className={`txtbox `}
                                    id='zipsearch'
                                    placeholder="Search with zip code"
                                    value={searchTerm}
                                    onChange={searchOnchange}
                                    onKeyUp={(e) => {
                                        if (e.keyCode === 13) {
                                            doSearch();
                                        }
                                    }}
                                    type='text' />
                                <button
                                    className={`search-btn`}
                                    onClick={doSearch}
                                    type='button'>
                                    <MDBIcon fas icon="search" />
                                </button>
                            </MDBInputGroup>
                            
                            
                        </div>
                        <div className='d-flex  w-auto'>
                        {/* <MDBBtn color='primary'>Search</MDBBtn> */}
                        {/* <button type="button" id="btn_cbo" className="d-flex btn btn-link btn-cbo" onClick={() => window.location.replace(`${appConfig.apiBaseUrl}/admin`)}>ADMIN</button> */}
                        {/* <button type="button" id="btn_cbo" className="d-flex btn btn-link btn-cbo btn-res" onClick={() => toggleOpen('cbo')}>Login as CBO <i
                            className="fa fa-arrow-right" aria-hidden="true"></i></button> */}
                        {/* <button type="button" id="btn_res" className="d-flex btn btn-link btn-res" onClick={() => toggleOpen('res')}>Resident</button> */}
                        {/* <button type="button" id="btn_res" className="d-flex btn btn-link btn-res" onClick={loginAsResident}>Resident</button> */}

                    </div></>}

                       
                        {/* <CBOSearch /> */}
                        {/* {
                            userType ==='CBO' && <NotificationsTray />
                        } */}
                        
                        <UserTray handleLogoutClick={handleLogoutClick} />
                    </MDBNavbarNav>

                </MDBContainer>
            </MDBNavbar>

            {modalType === 'cbo' && <CBOLoginModel setPrevModal={setPrevModal} switchModal={setModalType} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} toggleOpen={toggleOpen} />}
        </>
    )
}

export default Topbar