import React from 'react'


const TableTxt = () => {
    return (
    <>
       <h5 className="card-title border-dark  m-0">Greenhouse Gas Emission as per each facility 2012 - 2021<span className="badge badge-primary mx-2 ms-2">Units in Metric Tons</span></h5>
       <p style={{padding: "5px 25% 0px 0px"}}>The table below displays the emissions from each facility over the last decade, offering insight into the efforts made by each facility to reduce their emissions.</p>
    </>
    )
  
}

export default TableTxt