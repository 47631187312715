import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import './GaugeComponent.css';
import chartsConfig from '../../../configs/charts.config';

HighchartsMore(Highcharts);

const GuageComponent = ({ intensity, label, description }) => {

  const isUnavailable = intensity === null || intensity === 0;
  const opacity = isUnavailable ? 0.3 : 1;
  const options = {
    chart: {
      type: 'gauge',
      //marginBottom: -20,
    },

    title: null,

    pane: {
      startAngle: -90,
      endAngle: 90,
      size: '80%',
      background: [{
        backgroundColor: {
          stops: [
            [0, '#55bc64'],
            [1, '#f6fbf6']
          ]
        },
        innerRadius: '73%',
        outerRadius: '15%',
        shape: 'arc',
        borderWidth: 0,
      }]
    },

    yAxis: {
      min: 0,
      max: 100,
      lineWidth: 0,
      minorTickWidth: 0,
      tickWidth: 0,
      labels: {
        enabled: false,
      },
      plotBands: [
        {
          from: 0,
          to: 16.6,
          color: '#ff5656', 
          thickness: '20%',
          borderWidth: 0,
          label: {
            text: 'VHH',
            style: {
              fontWeight: 'bold',
              fontSize: '12px',
              color: '#000',
            },
            align: 'left',
            x: 17,
          }
        },
        {
          from: 16.6,
          to: 33.3,
          color: '#ff8888', 
          thickness: '20%',
          borderWidth: 0,
          label: {
            text: 'HH',
            style: {
              fontWeight: 'bold',
              fontSize: '12px',
              color: '#000',
            },
            align: 'center',
            x: 27, 
          }
        },
        {
          from: 33.3,
          to: 50,
          color: '#fee114', 
          thickness: '20%',
          borderWidth: 0,
          label: {
            text: 'MH',
            style: {
              fontWeight: 'bold',
              fontSize: '12px',
              color: '#000',
            },
            align: 'right',
            y : 1,
            x : 2,
          }
        },
        {
          from: 50,
          to: 66.6,
          color: '#d1d80f', 
          thickness: '20%',
          borderWidth: 0,
          label: {
            //text: 'WPHL',
            text: 'LH',
            style: {
              fontWeight: 'bold',
              fontSize: '12px',
              color: '#000',
            },
          }
        },
        {
          from: 66.6,
          to: 83.3,
          color: '#84bd32', 
          thickness: '20%',
          borderWidth: 0,
          label: {
            //text: 'PC',
            text: 'VLH',
            style: {
              fontWeight: 'bold',
              fontSize: '12px',
              color: '#000',
            },
          }
        },
        {
          from: 83.3,
          to: 100,
          color: '#30ad43', 
          thickness: '20%',
          borderWidth: 0,
          label: {
            //text: 'LH',
            text: 'PC',
            style: {
              fontWeight: 'bold',
              fontSize: '12px',
              color: '#000',
            },
          }
        },
      ],
    },

    plotOptions: {
      gauge: {
        dataLabels: {
          enabled: false
        },
        dial: {
          radius: '70%',
          backgroundColor: 'black',
          topWidth: 0,
          baseWidth: 10,
          baseLength: '5%',
        },
        pivot: {
          radius: 10,
          backgroundColor: 'black',
        }
      }
    },

    series: [
      {
        name: 'Intensity',
        data: intensity !== null && intensity !== 0 ? [intensity] : [],
        tooltip: {
         // valueSuffix: ' units',
        },
      },
    ],
    credits: false
  };

  /*const options = chartsConfig.gauge;
  options.series = [
    {
      name: 'Intensity',
      data: intensity !== null && intensity !== 0 ? [intensity] : [],
      tooltip: {
        valueSuffix: ' units',
      },
    }
  ];*/
  //console.log('options : '+JSON.stringify(options));
  return (
    <div className={`tile ${isUnavailable ? 'unavailable' : ''}`}>
      {/*isUnavailable && <div className="unavailable-text">Unavailable</div>*/}
      {/* When any Chemical data is not available, then reducing the opacity of the Gauge meter. */}
      <div style={{ opacity }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
      </div>
      
      <div className="label-container">
        <span className="label">{label}</span>
        <p className="description">{description}</p>  
      </div>
       
    </div>
  );
}

export default GuageComponent;