import React, { useEffect, useMemo, useState } from 'react'
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
// import { AgGridReact } from 'ag-grid-react';
// import 'ag-grid-community/styles/ag-grid.css'; // Core CSS
// import 'ag-grid-community/styles/ag-theme-quartz.css';
// import { DataGrid, GridColumn, NumberBox, TextBox, TreeGrid } from 'rc-easyui';
import { Space, Switch, Table } from 'antd';
import './Grid.css';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit';

const Grid = ({ gridData, gridHeight, activeTab }) => {
    // const [rowData, setRowData] = useState([]);
    const [gridComponent, setGridComponent] = useState([]);
    const [rowData, setRowData] = useState([]);
    const [colDefs, setColDefs] = useState([]);
    const [data, setData] = useState([]);

    useEffect(() => {
        let groupColName = null;
        setColDefs(() => {
            if (activeTab === 'TRI' || activeTab === 'GHG') {
                return gridData.headers.map((lbl, i) => {
                    return {
                        title: (i === 0) ? lbl.replace('{tab}', activeTab) : lbl,
                        dataIndex: (i === 0) ? 'feature' : `_${lbl}`,
                        key: (i === 0) ? 'feature' : `_${lbl}`,
                        width: (i === 0) ? '25vw' : 120,
                        fixed: (i === 0) ? 'left' : '',
                        sorter: (a, b) => a[(i === 0) ? 'feature' : `_${lbl}`] < b[(i === 0) ? 'feature' : `_${lbl}`],
                        sortDirections: ['descend', 'ascend'],
                        defaultSortOrder: 'ascend',
                    }
                });
            } else {
                return gridData.headers.map((lbl, i) => {
                    return {
                        title: (i === 0) ? lbl.replace('{tab}', activeTab) : lbl,
                        dataIndex: (i === 0) ? 'feature' : lbl,
                        key: (i === 0) ? 'feature' : lbl,
                        // width: (i === 0) ? '25vw' : 120,
                        fixed: (i === 0) ? 'left' : '',
                        sorter: (a, b) => a[(i === 0) ? 'feature' : lbl] < b[(i === 0) ? 'feature' : lbl],
                        sortDirections: ['descend', 'ascend'],
                        defaultSortOrder: 'ascend',
                    }
                    
                });
            }
        });
        
        if (activeTab !== 'TRI' && activeTab !== 'GHG') {
            setRowData(gridData.data);
        } else {
            const footerRow = {};
            const finalData = gridData.data.map((rec, i) => {
                Object.keys(rec).map((key, ki) => {
                    if (key.indexOf('_') === 0) {
                        if (!footerRow[key]) {
                            footerRow[key] = 0;
                        }
                        footerRow[key] = parseFloat(footerRow[key]);
                        footerRow[key] += parseFloat(rec[key]);
                        footerRow[key] = parseFloat(footerRow[key]);
                        // rec[key] = rec[key]?.toFixed(2);
                        footerRow[key] = footerRow[key]?.toFixed(2);
                        rec[key] = parseFloat(rec[key]).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                    } else {
                        if (key === 'feature')
                            footerRow[key] = 'Total';
                        if (key === 'County')
                            footerRow[key] = '';
                    }
                });
                // Object.keys(rec).map((key, ki) => {
                //     if (key !== 'feature' && key !== 'County') {
                //         rec[key] = parseFloat(rec[key]).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                //     }
                // })

                return rec
            })
            Object.keys(footerRow).map((key, ki) => {
                if (key !== 'feature' && key !== 'County') {
                    footerRow[key] = parseFloat(footerRow[key]).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                }
            })
            finalData.push(footerRow);
            setRowData(finalData);
        }
    }, [gridData]);

    // useEffect(() => {
    //     setGridComponent(() => {
    //         return renderdrid();
    //     });
    // }, [colDefs, rowData]);

    const getData = () => {
        let data = gridData.data;
        if (activeTab !== 'TRI') {
            let data = gridData.features.map((feature, fi) => {
                let row = {};
                row['feature'] = feature;
                Object.keys(gridData.data[feature]).map((value, vi) => {
                    row[`_${value}`] = gridData.data[feature][value].toFixed(2)
                    return row
                });
                return row;
            })
        }
        setData(data);
        return data;
    }

    return (
        <>
            {/* {gridComponent} */}
            <Table
                className={activeTab.toLowerCase() +'-grid'}
                columns={colDefs}
                dataSource={rowData}
                scroll={{ y: 400 }}
                pagination={false}
            />
        </>
    );
}

export default Grid;
