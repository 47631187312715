import React from 'react'
import {
  MDBIcon
} from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';


const SvmMapTxt = () => {
    return (
      <>
        <p><strong>Social Vulnerability Module</strong> is an indicator within the  Environmental Justice Index <Link to="/app/glossary" target='_blank'><u>(EJI)</u></Link> . For a better insight, we are looking at each EJI indicator individually instead of the overall EJI Score.  </p>
        <p>Every community must prepare for and respond to hazardous events, whether a natural disaster like a tornado or disease outbreak, or a human-made event such as a harmful chemical spill. Social vulnerability refers to a community’s ability to respond to these events. Social vulnerability is measured using factors including poverty, lack of access to transportation, and crowded housing that may weaken a community’s ability to prevent human suffering and financial loss in a disaster.  </p>
        <p>The map represents Social Vulnerability Module Indicators, which capture population characteristics that may influence the ability of a community to respond to environmental hazards or influence environmental decision-making. </p>
        <p><strong>Social Vulnerability Module was required to satisfy the following criterion:  </strong></p>
        <ol>
            <li>The populations represented in the indicator have less capacity to improve environmental conditions or advocate against unwanted land uses in their communities because of historical or ongoing discrimination or other factors. </li>
        </ol>
        <p>SVM scores are ranked between 0-1, with zero representing the lowest relative burden/vulnerability and 1 representing the highest relative burden/vulnerability. </p>
     </>
    )
  
}

export default SvmMapTxt
